import { useChainId, useReadContract, UseReadContractReturnType } from 'wagmi';
import { StellarBytesAstroUpgradeableObject } from '../constants/contractsConfig';
import {
    StructReturnsAnalyticsAndDefaultsStructOutput,
    StructUserAccountReturnStructOutput,
} from '../../web3/contracts/typechain-types/contracts/StellarBytesAstroUpgradeable.sol/StellarBytesAstroUpgradeable';
import { zeroAddress } from 'viem';
import { StellarBytesAstroUpgradeableABI } from '../constants/abis';

export const useReadStellarBytesAstroContract = (
    functionName:
        | 'owner'
        | 'UPGRADE_INTERFACE_VERSION'
        | 'getContractAnalytics'
        | 'getPrice'
        | 'getUSDValue'
        | 'getUserAccount'
        | 'getUserStakingsReward'
        | 'pendingOwner'
        | 'proxiableUUID',
    args:
        | readonly []
        | readonly [`0x${string}`]
        | readonly [bigint, bigint]
        | undefined,
) => {
    const chainId = useChainId();
    const contractObject = StellarBytesAstroUpgradeableObject[chainId];

    // @ts-ignore
    const result = useReadContract({
        abi: StellarBytesAstroUpgradeableABI,
        address: contractObject.contractAddress,
        functionName: functionName,
        args: args,
        blockTag: 'latest',
    });

    return result;
};

export type UseReadContractWithUserAccountType = Omit<
    UseReadContractReturnType,
    'data'
> & {
    data: StructUserAccountReturnStructOutput | undefined;
};

export const useGetUserAccount = (address: `0x${string}` | undefined) => {
    address = address ?? zeroAddress;
    const result = useReadStellarBytesAstroContract('getUserAccount', [
        address,
    ]) as UseReadContractWithUserAccountType;

    return result;
};

export const useGetContractAnalytics = () => {
    let result = useReadStellarBytesAstroContract('getContractAnalytics', []);
    const resultWithType = {
        ...result,
        data: result?.data,
    } as
        | (typeof result & {
              data: StructReturnsAnalyticsAndDefaultsStructOutput;
          })
        | undefined;

    return resultWithType;
};

export const useGetUserPendingStakingRewards = (
    address: `0x${string}` | undefined,
) => {
    let result = useReadStellarBytesAstroContract('getUserStakingsReward', [
        address ?? zeroAddress,
    ]);

    const resultWithType = {
        ...result,
        data: result?.data,
    } as
        | (typeof result & {
              data: bigint;
          })
        | undefined;

    return resultWithType;
};
