import { polygon } from 'viem/chains';
import { StellarBytesAstroUpgradeableABI } from './abis';
import { StellarBytesContractObjectForChainType } from './types';
import StellarBytesAstroUpgradeableProxyObjectPolygon from '../web3/contracts/.openzeppelin/polygon.json';

export const StellarBytesAstroUpgradeableObject: StellarBytesContractObjectForChainType =
    {
        [polygon.id]: {
            contractAddress: StellarBytesAstroUpgradeableProxyObjectPolygon
                ?.proxies?.[
                StellarBytesAstroUpgradeableProxyObjectPolygon?.proxies
                    ?.length - 1
            ]?.address as `0x${string}`,
            abi: StellarBytesAstroUpgradeableABI,
            supportedTokenParams: [
                {
                    name: 'Polygon',
                    symbol: 'MATIC',
                    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
                    isNative: true,
                    aggregator: '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0',
                },
                {
                    name: 'Dai Stablecoin',
                    symbol: 'DAI',
                    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
                    isNative: false,
                    aggregator: '0x4746dec9e833a82ec7c2c1356372ccf2cfcd2f3d',
                },
                {
                    name: 'Tether',
                    symbol: 'USDT',
                    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
                    isNative: false,
                    aggregator: '0x0A6513e40db6EB1b165753AD52E80663aeA50545',
                },
                {
                    name: 'USD Coin',
                    symbol: 'USDC',
                    address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
                    isNative: false,
                    aggregator: '0x572dDec9087154dC5dfBB1546Bb62713147e0Ab0',
                },
                {
                    name: 'ChainLink',
                    symbol: 'LINK',
                    address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
                    isNative: false,
                    aggregator: '0xd9FFdb71EbE7496cC440152d43986Aae0AB76665',
                },
            ],
        },
    };
