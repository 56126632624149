import { Heading, Icon, useColorModeValue, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

const DashboardItemContainer = ({
    children,
    heading,
    icon,
}: {
    children: ReactNode;
    heading?: string;
    icon: IconType;
}) => {
    return (
        <VStack
            p={5}
            spacing={5}
            borderWidth={'thin'}
            borderRadius="3xl"
            bgColor={useColorModeValue('white', 'gray.900')}
            minW={[275, 275, 0]}
            minH={400}
        >
            {heading && <Heading size="md">{heading}</Heading>}
            <Icon as={icon} boxSize={20}></Icon>
            {children}
        </VStack>
    );
};

export default DashboardItemContainer;
